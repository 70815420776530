<template>
  <!-- 个人车牌页面 -->
  <div class="plateNumber_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>{{ pageTitle }}</span>
      </div>
    </div>

    <div class="plateNumber_list">
      <div class="plateNumber_list_scroll">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div
              class="plateNumber_item"
              v-for="(item, index) in list"
              :key="index"
              @click="onClickItem(item)"
            >
              <div class="item_left">
                <div class="location">
                  <img src="../assets/images/停车场.png" alt="" />
                  {{ item.parkingName }}
                </div>
                <div class="parkingTime">
                  停车时长：{{ item.timeDifference }}
                </div>
                <div class="leaveTime">
                  出场时间：{{
                    item.exitOperatorTime | dateFilter_yy_MM_dd_hh_mm_ss
                  }}
                </div>
              </div>
              <div class="item_right">
                <span :class="pageClassName">{{
                  pageState == 40 ? item.arrearAmount : item.orderAmount
                }}</span>
                元
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <div class="plateNumber_total">
        {{ pageState == 40 ? "欠费总额" : "减免总额" }}
        <span :class="pageClassName">{{ totalAmount }}</span> 元
      </div>
      <div class="plateNumber_total_box"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    list: [],
    loading: false,
    finished: false,
    refreshing: false,

    pageState: 0, //页面状态
    pageTitle: null, //导航栏标题
    plateNumber: "", //车牌号码
    total: 0, //数据总条数
    totalAmount: 0, //总费用
    pageNum: 1, //页码
    pageSize: 10 //每页数量
  }),
  methods: {
    /**
     * 加载列表
     */
    onLoad() {
      // this.$get(this.$config.TL_MESSAGE_GET_PARKING_RECORD_BY_NUMBER, {
      //   plateNumber: this.plateNumber,
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize,
      //   type: this.pageState
      // })
      this.$api
        .getParkingRecordByNumber({
          plateNumber: this.plateNumber,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: this.pageState
        })
        .then(res => {
          console.log("今日关注  ==> ", res);
          if (this.refreshing) {
            this.list = [];
            this.refreshing = false;
          }

          this.total = res.data.data.total;
          this.totalAmount = res.data.data.totalAmount;

          this.pageNum++;

          res.data.data.rows.forEach(item => {
            this.list.push(item);
          });

          this.loading = false;

          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
        });
    },

    /**
     * 刷新列表
     */
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      this.pageNum = 1;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },

    onClickItem(item) {
      console.log("item ==> ", item);
      this.$router.push({ name: "OrderDetail", params: item });
    }
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp
    }),
    pageClassName() {
      if (this.pageState == 20) {
        return "free";
      } else if (this.pageState == 21) {
        return "whiteList";
      } else if (this.pageState == 40) {
        return "arrear";
      }

      return "";
    }
  },

  created() {
    let { pageState, plateNumber } = this.$route.query;
    this.pageState = pageState;
    this.plateNumber = plateNumber;
    if (plateNumber) {
      this.pageTitle = plateNumber;
    }
  }
};
</script>

<style lang="less" scoped>
.plateNumber_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.plateNumber_list {
  flex: 1;
  width: 100%;
  padding: 14px;
  background-color: #edf0f7;

  position: relative;

  .plateNumber_list_scroll {
    overflow: scroll;
    height: 100%;
    width: calc(100% + 80px);
    padding: 0 40px;
    transform: translateX(-40px);
  }

  .plateNumber_item {
    width: 100%;
    padding: 10px 14px;
    background-color: #fff;
    margin-bottom: 14px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;

    .item_left {
      .location {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        > img {
          width: 20px;
          height: auto;
          margin-right: 4px;
        }
      }

      .parkingTime,
      .leaveTime {
        font-size: 12px;
        color: #a0a0a0;
      }

      .parkingTime {
        margin-bottom: 8px;
      }
    }

    .item_right {
      display: flex;
      align-items: center;
      margin-right: 8px;
      font-weight: 600;
      color: #9b9b9b;
      font-size: 12px;

      span {
        color: #fa5858;
        font-size: 20px;
        margin-right: 4px;
      }
    }
  }

  .plateNumber_total {
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    padding: 8px 24px;
    color: #fff;
    background-color: #48484b;
    border-radius: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;

    span {
      font-size: 18px;
      margin: 0 8px;
      color: #e75151;
      font-weight: 600;
    }
  }

  .plateNumber_total_box {
    height: 50px;
  }
}

.arrear {
  color: #fa5e5e !important;
}

.free {
  color: #40c09d !important;
}
.whiteList {
  color: #288ee7 !important;
}
</style>
